import "./asset/common.css";
import "./asset/contents.css";
import "./asset/reset.css";
import "./asset/detail.css";
import Router from "./router/Router";

function App() {
  return (
    <>
      <Router />
    </>
  );
}

export default App;
